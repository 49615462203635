export default function ntInitiatePayment(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'NT_INITIATE_PAYMENT_SUCCESS':
      return {...state, ...{success: action.success,error:null}};
    case 'NT_INITIATE_PAYMENT_ERROR':
      return {...state, ...{error: action.error.response,success:null}};
    case 'NT_INITIATE_PAYMENT_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'RESET_NT_INITIATE_PAYMENT':
      return {...state, ...{success:null,error:null}};
    default:
      return state;
  }
}
