export default function successMessage(state = { message: "" }, action) {
  switch (action.type) {
    case "ADD_SUCCESS_MESSAGE":
      return { ...state, ...{ message: action.message } };

    case "DELETE_SUCCESS_MESSAGE":
      console.log("here")
      return { ...state, ...{ message: "" } };

    default:
      return state;
  }
}
