export default function initiateStripePayment(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'INITIATE_STRIPE_PAYMENT_SUCCESS':
      return {...state, ...{success: action.success}};
    case 'INITIATE_STRIPE_PAYMENT_ERROR':
      return {...state, ...{error: action.error.response}};
    case 'INITIATE_STRIPE_PAYMENT_PROCESSING':
      return {...state, ...{processing: action.processing}};
    case 'RESET_INITIATE_STRIPE_PAYMENT':
      return {...state, ...{error:null, success: null}};
    default:
      return state;
  }
}
