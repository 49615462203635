import axios from 'axios';
import store from '../../Store'
import {disableLoader, enableLoader} from "../loaders/loader";

function _success(success) {
  return {type: 'ABORT_PAYMENT_SUCCESS', success}
}

function _error(error) {
  return {type: 'ABORT_PAYMENT_ERROR', error}
}

export function resetInitiateStripePayment() {
  return dispatch => {
    dispatch({
      type: "RESET_ABORT_PAYMENT"
    });
  }
}


export const abortPayment = (data) => {
  return dispatch => {
    store.dispatch(enableLoader())
    let config = {
      url: localStorage.getItem('base_url') + `payment/abort`,
      method: "post",
      dataType: 'json',
      data: data,
      headers: {}
    };

    return axios(config)

      .then(res => {
        store.dispatch(disableLoader())
        return res

      }).catch(error => {
      store.dispatch(disableLoader())
        return error
    });
  }
}
