import React from 'react'
import logo from "../../assets/img/brand/logo.png";
import { Col, Row } from "reactstrap";

const PaymentHeader = (props) => {
  let {
    data
  } = props;
  let payment_for = data.payment_for
  let stringArray  = payment_for ? payment_for.split("("): [""]
  let title = stringArray[0]
  let description = stringArray[1]? stringArray[1].split(")")[0]: ""
  return (
    <>
      <div className="mb-1 d-none ">
        <div className="row justify-content-center">
          <div className="col-sm col-md-8">
            <table className="table">
              <tbody>
                <tr>
                  <td className=" border-0">
                    <small className="text-muted">Paying for</small>
                    <p className="m-0">{title}</p>
                    {/*{description ? <p className="m-0 text-small text-muted">{description}</p>: <div></div>}*/}
                  </td>
                  <td className="text-right border-0" width="100">
                    <small className="text-muted">NRs.</small>
                    <p className="m-0">{data.amount}</p>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th className="text-right align-bottom border-top">Total:</th>
                  <td className="text-right">
                    <span className="h3">{data.amount}</span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>



      <div className="bg-white pt-3 pb-4 shadow-lg pt-md-5 mb-5">
        <div className="container">
          <table className="table mb-0">
            <thead>
              <tr>
                <td className="border-0 py-0 pb-2"><small className="text-muted">Paying For</small></td>
                <td className="border-0 py-0 pb-2 text-right"><small className="text-muted">NRs</small></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className=" border-0 pt-0">
                  <strong>{title}</strong><br/>
                  {description && <small className="text-muted">{description}</small>}
                </td>
                <td className="text-right border-0 pt-0" width="100">
                  <span className="h3 ">{data.amount}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default PaymentHeader
