import {combineReducers} from "redux";

import successMessage from "../reducers/messages/success-message"

import initiatePayment from './topup/initiate-payment'
import paymentConfirm from './topup/payment-confirm'

import initiateNcellPayment from './topup/initiate-ncell-payment'

import initiateQr from './topup/initiate-qr'
import confirmQr from './topup/confirm-qr'

import initiateStripePayment from './payment-gateways/initiate-stripe-payment'

import ntInitiatePayment from './ntTopup/nt-initiate-payment'
import ntPaymentConfirm from './ntTopup/nt-payment-confirm'

import getPaymentConfig from './payment/get-payment-config'

import loader from './loaders/loader'

import networkError from './network-error'

import getPaymentGateway from './payment-gateways/get-payment-gateway'
import getPaymentGateways from './payment-gateways/get-payment-gateways'

import getNpayBank from './payment/get-npay-bank'
import npayValidate from './payment/npay-validate'

import errorHandling from './error'


const allReducers = combineReducers({
  successMessage,
  initiatePayment,paymentConfirm,initiateNcellPayment,
  initiateQr, confirmQr,
  initiateStripePayment,
  ntInitiatePayment, ntPaymentConfirm,
  getPaymentConfig,
  loader,
  getPaymentGateway,getPaymentGateways,
  networkError,
  getNpayBank,
  npayValidate,
  errorHandling
});

export default allReducers;
