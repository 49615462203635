import axios from 'axios';
import {disableLoader, enableLoader} from "../loaders/loader";
import store from '../../Store'

function _success(success) {
  return {type: 'VALIDATE_NPAY_SUCCESS', success}
}

function _error(error) {
  return {type: 'VALIDATE_NPAY_ERROR', error}
}

export function resetNpayValidate() {
  return dispatch => {
    dispatch({
      type: "RESET_VALIDATE_NPAY"
    });
  };
}


export function npayValidate(data) {
  return dispatch => {

    store.dispatch(enableLoader())

    let config = {
      url: localStorage.getItem('base_url') + `npay/ebanking` ,
      method: "post",
      dataType: 'json',
      data: data,
      headers: {}
    };

    axios(config)

      .then(res => {
        store.dispatch(disableLoader())
        // dispatch(_processing(false));
        dispatch(_success(res));

      }).catch(error => {
      store.dispatch(disableLoader())
      dispatch(_error(error));
      // dispatch(_processing(false));
    })

    // return {type: 'INITIATE_PAYMENT_SUCCESS', payload: request}
  }
}

export default npayValidate;
