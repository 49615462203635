import axios from 'axios';
import store from '../../Store'
import {disableLoader, enableLoader} from "../loaders/loader";
import {networkError} from "../messages/success_message";
import {noInternet, serverError} from "../error";
import {Config} from "../../Config";

function _success(success) {
  return {type: 'FETCH_PAYMENT_CONFIG_SUCCESS', success}
}

function _error(error) {
  return {type: 'FETCH_PAYMENT_CONFIG_ERROR', error}
}

export function resetPaymentConfig() {
  return dispatch => {
    dispatch({
      type: "RESET_PAYMENT_CONFIG"
    });
  }
}


export function getPaymentConfig(data, slug) {
  return dispatch => {
    store.dispatch(enableLoader())
    let path = `${slug}/payment`
    if ((slug === "esewa") && (Config.useV2API)) {
      path = `v2/${slug}/initiate`
    } else if ((slug === "khalti") && (Config.useKhaltiV2Api)) {
      path = `v2/${slug}/initiate`
    }
    let url = localStorage.getItem('base_url') + path

    let config = {
      url: url,
      method: "post",
      dataType: 'json',
      data: data,
      headers: {}
    };

    axios(config)

      .then(res => {
        store.dispatch(disableLoader())
        console.log(res);
        dispatch(_success({...res, selectedPaymentMethod: slug}));

      }).catch(error => {
      if (error.response) {
        if (error.response.status >= 500)
          store.dispatch(serverError())
        dispatch(_error(error));
      } else if (error.request) {
        store.dispatch(noInternet())
      }
      store.dispatch(disableLoader())
    });
  }
}
 export default getPaymentConfig;
