import axios from 'axios'
import store from '../../Store'
import {disableLoader, enableLoader} from "../../actions/loaders/loader";

export const downloadFile = (url,name) => {
  store.dispatch(enableLoader())
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name+'.pdf');
    document.body.appendChild(link);
    link.click();
    store.dispatch(disableLoader())
  })
    .catch(err=>{
      console.log(err)
      store.dispatch(disableLoader())
    });
}
