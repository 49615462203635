import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Payment from '../views/Wallet/Payment'
import IndividualPayment from '../views/Wallet/IndividualPayment'
import ConfirmPayment from '../views/Wallet/ConfirmPayment'
import '../assets/home.css'
import NotFoundPage from '../views/NotFoundPage'
import Test from '../views/Test'
import '../assets/css/loaders.css'
import AlertModal from "../shared/form-helpers/AlertModal";
import {resetError} from "../actions/error";
import store from '../Store'
import PaymentCancel from "../views/Wallet/PaymentCancel";

class Routes extends React.Component {
  state = {
    isLoader: false,
    serverError: null,
    noInternet: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let {loader,errorHandling} = nextProps
    let {isLoader,noInternet,serverError} = prevState

    isLoader = loader.isLoader


    noInternet = errorHandling.no_internet

    serverError = errorHandling.server_error

    return {
      isLoader,noInternet,serverError
    }
  }


  toggleAlert() {
    store.dispatch(resetError())
  }

  render() {
    let {isLoader,noInternet,serverError} = this.state
    return (
      <>
        {!!noInternet &&
        <AlertModal
          open={!!noInternet}
          status={503}
          statusTitle="No Internet Connection"
          statusMessage="Either you have no internet connection or have slow internet connection."
          toggle={this.toggleAlert.bind(this)}
        />}
        {!!serverError &&
        <AlertModal
          open={!!serverError}
          status={500}
          statusTitle="Server Error"
          statusMessage="Oops !! Something went wrong with the server"
          toggle={this.toggleAlert.bind(this)}
          />
        }
        {isLoader && <div className="loading"></div>}
        <Switch>
          <Route exact path="/" component={Payment}/>
          <Route exact path="/test/test/test" component={Test}/>
          <Route exact path="/payment/confirm" component={ConfirmPayment}/>
          <Route exact path="/payment/cancel" component={PaymentCancel}/>
          <Route exact path="/payment/:id" component={IndividualPayment}/>
          <Route path='*' exact={true} component={NotFoundPage}/>
        </Switch>
      </>
    );
  }
}

function mapStateToProps(state) {
  let {loader,errorHandling} = state
  return {
    loader,
    errorHandling
  }
}

export default withRouter(connect(mapStateToProps)(Routes));
