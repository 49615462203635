import axios from 'axios';
import { disableLoader, enableLoader} from "../loaders/loader";
import store from '../../Store'

function _success(success) {
  return {type: 'INITIATE_PAYMENT_SUCCESS', success}
}

function _error(error) {
  return {type: 'INITIATE_PAYMENT_ERROR', error}
}

export function resetInitiatePayment() {
  return dispatch => {
    dispatch({
      type: "RESET_INITIATE_PAYMENT"
    });
  };
}


export function initiatePayment(data,slug) {
  return dispatch => {

    store.dispatch(enableLoader())

    initialPaymentService(data,slug)
      .then(res => {
        store.dispatch(disableLoader())
        // dispatch(_processing(false));
        dispatch(_success(res));

      }).catch(error => {
        store.dispatch(disableLoader())
        dispatch(_error(error));
        // dispatch(_processing(false));
      })

    // return {type: 'INITIATE_PAYMENT_SUCCESS', payload: request}
  }
}

export function initialPaymentService(data,slug) {
  let config = {
    url: localStorage.getItem('base_url') + slug +  `/initiate` ,
    method: "post",
    dataType: 'json',
    accept: "application/json",
    contentType: "application/json",
    data: data,
    headers: { 'content-type': 'application/json' }
  };

  if(slug === "ntc"){
    config["url"] =  localStorage.getItem('base_url') +  `ntc/generate-auth-password`
  } else if(slug === "paypal"){
    config["url"] =  localStorage.getItem('base_url') +  `paypal/payment`
  }
  return axios(config)
}

export default initiatePayment;
