export default function getPaymentConfig(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'FETCH_PAYMENT_CONFIG_SUCCESS':
      return {...state, ...{success: action.success,error:null}};
    case 'FETCH_PAYMENT_CONFIG_ERROR':
      return {...state, ...{error: action.error.response,success: null}};
    case 'RESET_PAYMENT_CONFIG':
      return {...state, ...{error: null,success: null}};
    default:
      return state;
  }
}
