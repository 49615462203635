import React, {useEffect, useState} from 'react';
import store from '../../Store'
import initiateQr from "../../actions/topup/initiate_qr";
import {connect} from "react-redux";
import QRCode from "react-qr-code";
import SnackBar from "../../shared/helpers/MessageSnackBar";
import {confirmQr} from "../../actions/topup/confirm_qr";
import {withRouter} from "react-router";

function QrCode(props) {
  const {id, data} = props
  const [text, setText] = useState("")
  const [onlineStatusCounter, setOnlineStatusCounter] = useState(0)
  const [initiateResponse, setInitiateResponse] = useState({})
  const [message, setMessage] = useState({variant: 'error'})
  let checkOffline = false

  var socket;
  var khaltiPayload = {}

  useEffect(() => {
    let url = id === "fonePay" ? "fonepay/qr-request": "khalti-qr/initiate"
    store.dispatch(initiateQr(url, data))
  }, [])

  useEffect(() => {
      const {success, error} = {...props.initiateQr}
      if(success && success.data){
        let payload = success.data
        let _text = id === "fonePay" ? payload.qrMessage : payload.payload
        setText(_text)

        if (id === "khalti-qr") {
          openWSS(payload.socket_url, payload.channel)
          setInitiateResponse(payload)
        } else
          openWSS(payload.thirdpartyQrWebSocketUrl)
      } else if (error) {
        if (error.status === 422 || error.status === 400 || error.status === 404) {
          let _message = null
          if (typeof error.data.message === 'string' || error.data.message instanceof String) {
            _message = error.data.message
          } else {
            let keys = Object.keys(error.data.message)
            _message = error.data.message[keys[0]]
          }
          setMessage({
            message: _message,
            variant: 'error'
          })
        }
      }
   }, [props.initiateQr])

  useEffect(() => {
    if(text){
        if (checkOffline) {
          clearInterval(checkOffline)
        }
        const timer = 2000
        checkOffline = setInterval(async () => {
          if (!window.navigator.onLine) {
            onRedirect()
          } else {
            let result = true;
            try {
              const online = await fetch(window.location.protocol+":"+ window.location.port+"//"+window.location.hostname+"/assets/1pixel.webp?_=" + new Date().getTime());
              result = online.status >= 200 && online.status < 500; // either true or false
              setOnlineStatusCounter(x => x + 1)
            } catch (err) {
              result = false; // definitely offline
            }
            if(!result){
              onRedirect()
            } else if(onlineStatusCounter > 60) {
              onRedirect()
            }
          }
        }, timer)
    }
  }, [text])

  useEffect(() => {
      const {success, error} = {...props.confirmQr}
      if(success && success.data){
        let payload = success.data
        props.history.push('/payment/confirm', {response: payload})
        setMessage({
          message: payload.message,
          variant: 'success',
        })
      } else if (error) {
        if (error.status === 422 || error.status === 400 || error.status === 404) {
          let _message = null
          if (typeof error.data.message === 'string' || error.data.message instanceof String) {
            _message = error.data.message
          } else {
            let keys = Object.keys(error.data.message)
            _message = error.data.message[keys[0]]
          }
          setMessage({
            message: _message,
            variant: 'error'
          })
        }
      }
   }, [props.confirmQr])

  function onRedirect() {
    props.history.push("/payment/cancel?status=fail")
  }

  function openWSS(url, channel) {
    console.log("here", url);
    socket = new WebSocket(url);
    socket.onopen = function (e) {
      console.log("connection established")
      if (id === "khalti-qr") {
        var msg = {
          event: "pusher:subscribe",
          data: {"channel": channel}
        }
        socket.send(JSON.stringify(msg))
      }
    };

    socket.onmessage = function (event) {
      let data = JSON.parse(event.data)
      console.log(data)
      try {
        if (id === "khalti-qr") {
          var items = JSON.parse(data.data)
          if (items && items.success) {
            var pathurl = "khalti-qr/confirm"
            let khaltiPayload  = initiateResponse
            khaltiPayload["bill_number"] = items["bill_number"];
            khaltiPayload["idx"] = items["idx"];
            khaltiPayload["amount"] = items["amount"];
            store.dispatch(confirmQr(pathurl, khaltiPayload))
          }
        } else if (data && data.transactionStatus) {
          var response = JSON.parse(data.transactionStatus)
          if (response.traceId) {
            var pathurl = "fonepay/qr-status"
            store.dispatch(confirmQr(pathurl, data.transactionStatus))
          }
        }
      } catch (e) {
        onRedirect()
      }
    };

    socket.onclose = function (event) {
      if (event.wasClean) {
        console.log("[close] Connection closed cleanly, code=" + event.code + " reason=" + event.reason);
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        console.log("[close] Connection died");
        onRedirect()
      }
    };

    socket.onerror = function (error) {
      console.log("[error] " + error.message);
      onRedirect()
    };
  }
  return (
    <>
    <div className="d-flex justify-content-center">
      <div style={{width: "400px", maxWidth: "800px"}}>
        <p className="qrpay_title">Scan {id} QR code to Pay</p>'
        <div className="qr-pay">
          <div style={{
            height: "calc(100% - 16px)", width: "calc(100% - 16px)", lineHeight: "100%",
            paddingTop: "16px", paddingLeft: "16px", margin: "0px"
          }}>
            {text ? <QRCode value={text} size={268}/>: <></>}
          </div>

          <svg viewBox="0 0 100 100" width="50px">
            <path d="M25,2 L2,2 L2,25" fill="none" stroke="#00A054" stroke-width="2"/>
            <path d="M2,75 L2,98 L25,98" fill="none" stroke="#00A054" stroke-width="2"/>
            <path d="M75,98 L98,98 L98,75" fill="none" stroke="#00A054" stroke-width="2"/>
            <path d="M98,25 L98,2 L75,2" fill="none" stroke="#00A054" stroke-width="2"/>
          </svg>
        </div>
      </div>
    </div>
      <SnackBar
        open={!!message.message}
        onClose={() => {setMessage({variant: "error"})}}
        variant={message.variant}
        message={message.message}
      />
      </>
  );
}

function mapStateToProps(state) {
  let {
    initiateQr,
    confirmQr
  } = state
  return {
    initiateQr,
    confirmQr
  }
}

export default withRouter(connect(mapStateToProps)(QrCode));
