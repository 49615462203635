import React from 'react';
import {Badge, FormGroup} from "reactstrap";

const ViewData = (props) => {
  let {
    label,
    data,
    isStatus,
    labels
  } = props

  return (
    <>
      <span className="form-span-label">{label}</span>
      <FormGroup>
        {
          data &&
          isStatus ?
            <Badge
              color={data === 1 ? "success" : "danger"} pill
              className="status-badge">{data === 1 ? labels.one : labels.zero}</Badge>
            :
            <span className="form-span-value">{data}</span>
        }
      </FormGroup>
    </>
  );
}

export default ViewData;
