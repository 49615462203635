export default function initiatePayment(
  state = { network_error: null},
  action
) {
  switch (action.type) {
    case 'NETWORK_ERROR':
      return {...state, ...{network_error: true}};
    case 'RESET_NETWORK_ERROR':
      return {...state, ...{network_error:null}};
    default:
      return state;
  }
}
