import React, { Component } from 'react'
import {Button, Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import axios from 'axios';
import {Config} from "../Config";

class Test extends Component{

  state = {
    data: {}
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    data[name] = value;
    this.setState({data});
  }

  onSubmit(){
    let randomNumber = Math.random().toString(36).substr(2, 9);
    let {data} = this.state
    data['product'] = randomNumber
    let config = {
      url: `${data.post_url}/payment/initiate` ,
      method: "post",
      dataType: 'json',
      data: data,
      headers: {}
    };
    axios(config)
      .then(res=>{
        console.log(res)
      })
  }


  render() {
    let { data} = this.state
    return (
      <div className="row justify-content-center" style={{background: '#ffffff', minHeight: '100vh'}}>
        <Card className="payment-card">
          <CardBody>
            <FormGroup>
              <Label>Post Url</Label>
              <Input name="post_url" onChange={(e)=>this.onChange(e)} value={data.post_url}/>
            </FormGroup>
            <FormGroup>
              <Label>Customer Code</Label>
              <Input name="customer_code" onChange={(e)=>this.onChange(e)} value={data.customer_code}/>
            </FormGroup>
            <FormGroup>
              <Label>Amount</Label>
              <Input name="amount" onChange={(e)=>this.onChange(e)} value={data.amount}/>
            </FormGroup>
            <Row><Col className="text-center"><Button color="success" onClick={this.onSubmit.bind(this)}>Next</Button></Col></Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Test
