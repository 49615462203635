import React, {Component} from 'react'
import logo from "../../assets/img/brand/89_25.png";
import {Card, CardBody, Col, Row} from "reactstrap";
import queryString from "query-string";
import {stringUpperCase} from "../../shared/helpers/GeneralHelpers";
import {isEmptyObject} from "../../shared/helpers/ArrayKey";
import {downloadFile} from "../../shared/helpers/DownloadFile";
import {Config} from "../../Config";
import {DetectMobile} from "../../shared/helpers/DetectMobile";

class PaymentCancel extends Component {
  state = {
    response: {},
    seconds: 10,
    redirected: false,
    parsedUrl: "",

  }

  componentDidMount() {
    if (!DetectMobile())
      setInterval(() => this.tick(), 1000)
  }

  tick() {
    if (this.state.seconds === 1 && this.state.redirected === false) {
      let callback_url = localStorage.getItem('callback_url')
      if (callback_url) {
        window.location = callback_url
        this.setState({
          redirected: true
        })
      }

    } else if (this.state.redirected === false)
      this.setState(state => ({
        seconds: state.seconds - 1
      }));
  }

  onDownload() {
    let {response} = this.state
    let url = Config.BillingUrl + `transactions/${response.transaction_id}?q=print`
    downloadFile(url, response.transaction_id)
  }

  onRedirect() {
    window.location = localStorage.getItem('callback_url')
  }

  render() {

    let {response, seconds} = this.state

    let responseKey = Object.keys(response)

    let viewResponse = responseKey.map((key) => {
      if (key === "redirect_url" || key === "time_out" || key === "status" || key === "message") {
        return null
      } else {
        return (
          <>
            <div className="text-muted">
              {stringUpperCase(key)}
            </div>
            <div className="text-bold">
              <span className="text-bold">{response[key]}</span>
            </div>
          </>
        )
      }
    })

    return (
      <div className="row justify-content-center payment-card-parent">
        <Card className="payment-card">
          <CardBody>
            <div className="bg-white pt-3 pb-4 shadow-lg mb-5 text-center">
              <h6 style={{
                height: "64px",
                width: "auto",
                fontSize: "40px",
                lineHeight: "64px",
                margin: "0"
              }}>ePayment</h6>
            </div>
            <div className="right-side-div">
              <Row className="justify-content-center mt-4">
                <Col sm="" md="6">
                  <Card color="danger">
                    <CardBody>
                      <div className="payment-info" style={{color: "#fff"}}>
                        <div className="text-center payment-text-box mt-3 mb-4">
                          <p className="text-center mb-0" style={{fontSize: "46px"}}><i className="icon-close icons "/></p>
                          <h4>Your payment has been cancelled. </h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {!DetectMobile() && <div>
                <p className="form-span-label text-center confirmation-text">
                  {this.state.seconds === 1 ?
                    <span>If you are not redirected back. Please Click <span className="span-link"
                                                                             onClick={() => this.onRedirect()}>here</span></span> : <span>You will be redirected back in {seconds} second automatically.  Or click <span
                      className="span-link"
                      onClick={() => this.onRedirect()}>here</span> to go back.</span>}
                </p>
              </div>}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default PaymentCancel
