const setCharAt = (str,index,chr) => {
  if (index > str.length - 1) return str;
  return str.substr(0, index) + chr + str.substr(index + 1);
}

const stringBreak = (word) => {
  let wordBreak = [];
  for (let i = 0; i < word.length; i++) {
    if (word.charAt(i) === "_")
      wordBreak.push(i)
  }
  let newWord = word;
  for (let i = 0; i < wordBreak.length; i++){
    newWord = setCharAt(newWord , wordBreak[i], ' ');
  }
  return newWord;
}


export const stringUpperCase = (word) => {
  let str = stringBreak(word);
  str = str.toLowerCase();
  return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
    function ($1) {
      return $1.toUpperCase();
    });
}
