import axios from 'axios';
import { disableLoader, enableLoader} from "../loaders/loader";
import store from '../../Store'

function _success(success) {
  return {type: 'INITIATE_NCELL_PAYMENT_SUCCESS', success}
}

function _error(error) {
  return {type: 'INITIATE_NCELL_PAYMENT_ERROR', error}
}

export function resetInitiatePayment() {
  return dispatch => {
    dispatch({
      type: "RESET_INITIATE_NCELL_PAYMENT"
    });
  };
}


export function initiateNcellPayment(data,slug, isApp = false) {
  return dispatch => {

    store.dispatch(enableLoader())
    if (slug === "ncell"){
      data["channel"] = isApp ? `APP` : `WEB`

      // data["amount"] = 9
      // data["product"] = "DMB-buy-1-8797-bsujan_home-5-web-9ClavnbgDe"
    }
    let config = {
      url: localStorage.getItem('base_url') + slug +  `/initiate` ,
      method: "post",
      dataType: 'json',
      accept: "application/json",
      contentType: "application/json",
      data: data,
      headers: { 'content-type': 'application/json' }
    };

    if(slug === "ntc"){
      config["url"] =  localStorage.getItem('base_url') +  `ntc/generate-auth-password`
    }else if (slug === "ncell"){
      config["url"] = localStorage.getItem('base_url') + `v2/ncell/payment`
    }
    // store.dispatch(disableLoader())
    // dispatch(_success({data:{"amount":9,"description":"Jatrai Jatra (2019)","action_url":"http:\/\/consent.ncell.axiata.com\/CGWAP\/wapconsent?accessToken=IOFYQOMIN4LBJEY28VXCJKIHTV0011622107838845TV001XVIDEOOP01JHCRR5FOF089DMB-buy-1-8797-bsujan_home-5-web-50et7zQEsX&cgTransactionId=LCQWOKPT-16221078388421622107838842","access_token":"IOFYQOMIN4LBJEY28VXCJKIHTV0011622107838845TV001XVIDEOOP01JHCRR5FOF089DMB-buy-1-8797-bsujan_home-5-web-50et7zQEsX"}}))

    axios(config)

      .then(res => {
        store.dispatch(disableLoader())
        // dispatch(_processing(false));
        dispatch(_success(res));

      }).catch(error => {
      store.dispatch(disableLoader())
      dispatch(_error(error));
      // dispatch(_processing(false));
    })

    // return {type: 'INITIATE_NCELL_PAYMENT_SUCCESS', payload: request}
  }
}

export default initiateNcellPayment;
