import React, {useEffect} from 'react';
import {Button, Col, Modal, ModalBody, Row} from "reactstrap";
import {withFocusable, withNavigation} from "react-tv-navigation";
import {connect} from "react-redux";

const AlertModal = (props) => {
  let {open,toggle,status,statusTitle,statusMessage} = props

  return (
    <Modal isOpen={open} size="lg" className={'modal-lg ' + props.className}>
      <ModalBody>
        <div className="text-center">
          <span className="status-code server-error">
            {status === 503 ? "503 !!" : status === 500 && "500 !!"}
          </span>
        </div>
        <div className="text-center"><span className="page-title">{statusTitle}</span></div>
        <div className="text-center"><span className="page-info">{statusMessage}</span></div>
        <hr/>
        <Row>
          <Col className="text-center">
            <FocusableButton click={() => toggle()}
                             focusPath={`alert-payment-done`}
                             color={"success"}
                             text={"Okay"}
                             onEnterPress={() => toggle()}/>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

const _Button = ({className, text, color, size = "lg", focused, setFocus, click}) => {
  function onClick() {
    setFocus()
    click()
  }

  return (
    <Button size={size} className={`${className} ${(focused) ? 'focus' : ''}`} color={color}
            onClick={() => onClick()}>
      {text}
    </Button>
  )
}
const FocusableButton = withFocusable(_Button)


export default withNavigation(AlertModal);
