import React, { Component, useEffect } from 'react';
import { connect } from "react-redux";
import { Button, Card, CardBody } from "reactstrap";
import store from '../../Store'
import { getPaymentConfig, resetPaymentConfig } from "../../actions/payment/get_payment_config";
import { disableLoader } from "../../actions/loaders/loader";
import queryString from "query-string";
import { isEmptyObject } from "../../shared/helpers/ArrayKey";
import PaymentHeader from "./PaymentHeader";
import { getPaymentGateways } from "../../apis/payment-gateway";
import MessageSnackBar from "../../shared/helpers/MessageSnackBar";
import { resetNetworkError } from "../../actions/messages/success_message";
import getNpayBank from "../../actions/npay/get_npay_bank";
import "./payment.css"
import { initiateStripePayment, resetInitiateStripePayment } from "../../actions/payment/initiate_stripe_payment";
import { withFocusable, withNavigation } from 'react-tv-navigation'
import { ntPaymentConfirm } from "../../actions/ntTopup/nt_payment_confirm";
import { abortPayment } from "../../actions/payment/abort_payment";

class Payment extends Component {

  state = {
    page: 1,
    data: {
      customer_code: "",
      amount: "",
      product: ""
    },
    paymentError: null,
    paymentConfig: {},
    initiateStripe: {},
    message: {},
    gateways: [],
    open: false,
    isNetwork: null,
    bankList: [],
    isLoader: false,
    isOpen: true
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      getPaymentConfig,
      initiateStripePayment,
      networkError,
      getNpayBank,
      loader
    } = nextProps

    let {
      paymentConfig,
      paymentError,
      stripePaymentError,
      isNetwork,
      bankList,
      isLoader,
      initiateStripe
    } = prevState

    if (initiateStripePayment) {
      let {success, error} = initiateStripePayment
      if (success) {
        initiateStripe = success.data
      } else if (error) {
        console.log(error)
        if (error.status === 422) {
          stripePaymentError = error.data.message
        } else if (error.data && error.data.message) {
          stripePaymentError = [error.data.message.toString()]
        }
      } else {
        stripePaymentError = null
      }
    }

    if (getPaymentConfig) {
      let {success, error} = getPaymentConfig
      if (success) {
        paymentConfig = success.data
        if(success.selectedPaymentMethod){
          paymentConfig['selectedPaymentMethod'] = success.selectedPaymentMethod;
        }


      } else if (error) {
        if (error.status === 422) {
          paymentError = error.data.message
        }
      } else {
        paymentError = null
      }
    }


    if (getNpayBank) {
      let {success} = getNpayBank
      if (success) {
        bankList = success.data
      }
    }

    isLoader = loader.isInnerLoader

    isNetwork = networkError.network_error

    return {
      paymentConfig,
      initiateStripe,
      paymentError,
      stripePaymentError,
      isNetwork,
      bankList,
      isLoader
    }
  }

  componentDidMount() {
    const response = queryString.parse(this.props.location.search);
    if (isEmptyObject(response) || !response['action_base_url'] || !response['callback_url'])
      this.props.history.push('/not-found')
    localStorage.setItem("base_url", response['action_base_url'] + '/')
    localStorage.setItem("callback_url", response['callback_url'])
    this.setState({
      data: response
    })
    getPaymentGateways()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { paymentConfig, initiateStripe, paymentError, stripePaymentError, isNetwork, bankList } = this.state
    let { getPaymentGateways } = this.props

    if (paymentError !== null) {
      this.setState({
        message: paymentError,
        isOpen: true
      }, () => {
        store.dispatch(resetPaymentConfig())
      })
    }
    if (stripePaymentError !== null) {
      this.setState({
        message: stripePaymentError,
        isOpen: true
      }, () => {
        store.dispatch(resetInitiateStripePayment())
      })
    }

    if (isNetwork !== null) {
      this.setState({
        open: true
      }, () => store.dispatch(resetNetworkError()))
    }

    if (paymentConfig !== prevState.paymentConfig) {
      if (paymentConfig.selectedPaymentMethod === "khalti") {
        window.location.href = paymentConfig['action_url']
      } else {

        let dataKeys = Object.keys(paymentConfig)
        var path = paymentConfig['action_url']
        var params = {}

        dataKeys.forEach((key) => {
          if (key !== "action_url")
            params[key] = paymentConfig[key]
        })

        var form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", path);

        for (var key in params) {
          var hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("name", key);
          hiddenField.setAttribute("value", params[key]);
          form.appendChild(hiddenField);
        }

        document.body.appendChild(form);
        store.dispatch(disableLoader())
        form.submit();
      }
    }

    if (initiateStripe && initiateStripe !== prevState.initiateStripe) {
      let gateways = this.state.gateways
      let data = this.state.data
      let stripe = gateways.findIndex(x => x.slug === "stripe")
      if (stripe > -1) {
        initiateStripe["logoUrl"] = gateways[stripe].logo
        initiateStripe["customer"] = data["customer_name"]
        initiateStripe["name"] = data["customer_name"]
        initiateStripe["callback_url"] = data["callback_url"]
        console.log(initiateStripe)
        this.props.history.push('/payment/stripe', {data: initiateStripe})
      } else {
        this.props.history.push('/payment/stripe', {data: initiateStripe})
      }
    }

    if (bankList.length > 0) {
      let data = {...this.state.data}
      this.setState({
        data
      }, () => {
        this.props.history.push('/payment/npay', { data: data, bankList: bankList })
      })
    }

    if (getPaymentGateways && getPaymentGateways !== prevProps.getPaymentGateways) {
      let { success } = getPaymentGateways
      if (success) {
        if (success.data) {
          this.setState({
            gateways: success.data.data
          }, () => {
            try {
              let index = success.data.data.findIndex(x => x.name.toLowerCase() === "paypal")
              if (index > -1) {
                let paypalMethod = success.data.data[index]
                let config = JSON.parse(paypalMethod.description)
                if (config.client_id && config.currency) {
                  let paymentScript = document.createElement("script")
                  // paymentScript.src = "https://www.paypal.com/sdk/js?client-id=Aa-WvPPJp1HKeOTV04NbLX28KFjS_Z_fU6OHf0NM-RJukv5RhhEIroto9yW4MBpvgInj8ZyBOwbfajHy&currency=USD"
                  paymentScript.src = `https://www.paypal.com/sdk/js?client-id=${config.client_id}&currency=${config.currency}`
                  document.body.appendChild(paymentScript);
                }
              }
            } catch (e) {

            }
          })
        }
      }

    }
  }

  onChange(e) {
    let { name } = e.target;
    let { value } = e.target;
    let data = { ...this.state.data };
    let error = { ...this.state.error };
    data[name] = value;
    error[name] = "";
    this.setState({ data, error });
  }

  onPayment(e, slug) {
    e.preventDefault()
    if (slug.toLowerCase() === "stripe")
      store.dispatch(initiateStripePayment(this.state.data, slug.toLowerCase()))
    else
      store.dispatch(getPaymentConfig(this.state.data, slug.toLowerCase()))
  }

  onKhalti(e, logoUrl) {
    e.preventDefault()
    let data = { ...this.state.data }
    data['logoUrl'] = logoUrl
    this.setState({
      data
    }, () => {
      this.props.history.push('/payment/khalti', { data: data })
    })
  }

  onNtc(e, logoUrl) {
    e.preventDefault()
    let data = { ...this.state.data }
    data['logoUrl'] = logoUrl
    this.setState({
      data
    }, () => {
      this.props.history.push('/payment/ntc', { data: data })
    })
  }

  onNcell(e, logoUrl) {
    e.preventDefault()
    let data = { ...this.state.data }
    data['logoUrl'] = logoUrl
    this.setState({
      data
    }, () => {
      this.props.history.push('/payment/ncell', { data: data })
    })
  }

  onMobilePay(e, slug, logoUrl) {
    e.preventDefault()
    let data = { ...this.state.data }
    data['logoUrl'] = logoUrl
    this.setState({
      data
    }, () => {
      this.props.history.push('/payment/' + slug, { data: data })
    })
  }

  onDismiss() {
    this.setState({ open: false })
  }

  onNpay() {
    store.dispatch(getNpayBank())
  }

  toggleMessage() {
    this.setState({ isOpen: false })
  }

  onRedirect() {
    store.dispatch(abortPayment({ product: this.state.data.product })).then((response) => {
      if (response.status === 200 || response.status === 201) {
        this.props.history.push("/payment/cancel?status=fail")
      } else {
        this.props.history.push("/payment/cancel?status=fail")
      }
    })

  }

  render() {
    let { message, gateways, open, bankList } = this.state

    let gatewaysOptions = gateways.map((gateway, key) => {
      let { slug, name, logo } = gateway
      let onClick = (slug === "ncell" || slug === "ntc" || slug === "paypal" || slug === "fonePay" || slug === "khalti-qr") ? (e) => this.onMobilePay(e, slug, logo) :
        slug === "npay" ? (e) => this.onNpay(e) :
          (e) => this.onPayment(e, slug)
      let classname = "";//"gateway-logo gateway-logo-margin"
      classname += slug === "khalti" ? " khalti" : ""
      // return _gateway(gateway, classname, onClick)
      return <Gateway key={key} gateway={gateway} classname={classname} click={onClick} focusPath={`item-${slug}`}
        onEnterPress={onClick} />
    })

    const keys = Object.keys(message)
    let esewaMessage = ""
    if (message[keys[0]])
      esewaMessage = <MessageSnackBar
        variant="error"
        message={message[keys[0]]}
        open={this.state.isOpen}
        onClose={this.toggleMessage.bind(this)}
      />

    return (
      <div className="container-fluid mx-0 px-0">
        <div className="row">
          {this.state.isLoader && <div className="loading"></div>}
          <div className="col-12  pb-md-5 px-0">
            <PaymentHeader
              data={this.state.data}
            />
            <form>
              <div className="container pb-5">
                <h4 className="text-center h5 text-muted mb-3">Select an option to continue</h4>
                <p className="text-muted small text-center d-none">Please purchase subscription from following payment
                  gateways.</p>
                <div className="row justify-content-center mb-4">
                  {gatewaysOptions}
                </div>
                <div className="row justify-content-center mb-5">
                  {
                    gateways.length > 0 &&
                    <FocusableButton click={() => this.onRedirect()} focusPath={`item-payment-cancel`}
                      onEnterPress={() => this.onRedirect()} />
                  }
                  {
                    gateways.length === 0 &&
                    <FocusableButton click={() => this.onRedirect()} focusPath={`item-payment-cancel11`}
                      onEnterPress={() => this.onRedirect()} />
                  }

                </div>
              </div>
            </form>
          </div>

          <div>
            {esewaMessage}
          </div>

          <MessageSnackBar
            onClose={this.onDismiss.bind(this)}
            variant="error"
            message="Something went wrong."
            open={open}
          />
        </div>
      </div>
    );
  }

}

const _Button = ({ focused, setFocus, click }) => {
  function onClick() {
    setFocus()
    click()
  }

  return (
    <Button size="lg" className={`px-3 text-center  ${(focused) ? 'focus' : ''}`} color="default"
      onClick={() => onClick()}>
      Cancel
    </Button>
  )
}

const FocusableButton = withFocusable(_Button)

const _Gateway = ({ focused, setFocus, gateway, classname, click, focusPath }) => {
  let description = {}
  try {
    description = gateway.description ? JSON.parse(gateway.description) : {}
  } catch (e) {
    description = {}
  }

  function onClick(e) {
    setFocus()
    click(e)
  }

  return <div className="col-sm-6 col-md-6 col-xl-3 mb-2 px-0 px-sm-2">
    <button
      className={`row rounded py-3 px-2 shadow-md bg-white h-100 w-100 b-2 method navigable ${(focused) ? 'focus' : ''}`}
      onClick={onClick} style={{ cursor: "pointer" }}
      id={`payment-list-${gateway.slug}`}
      title={gateway.name}>
      <div className="col-6">
        <h3 className="h4" style={{ color: description.color_code }}>{gateway.name}</h3>
        <p className="text-muted small mb-0">Pay via {gateway.name} e-payment</p>
      </div>
      <div className="col-6 text-center my-auto">
        <img src={gateway.logo || "/assets/payment.png"}
          className={`method_logo ${classname}`}
          width="100%"
          border="5" alt={gateway.name} key={gateway.name}
        />
      </div>
    </button>
  </div>
}

const Gateway = withFocusable(_Gateway)

function mapStateToProps(state) {
  let {
    getPaymentConfig,
    initiateStripePayment,
    getPaymentGateways,
    networkError,
    getNpayBank,
    loader
  } = state

  return {
    getPaymentConfig,
    initiateStripePayment,
    getPaymentGateways,
    networkError,
    getNpayBank,
    loader
  }
}

export default withNavigation(connect(mapStateToProps)(Payment));
