export default function getNpayBank(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {

    case 'FETCH_NPAY_BANK_SUCCESS':
      return {...state, ...{success: action.success,error:null}};
    case 'FETCH_NPAY_BANK_ERROR':
      return {...state, ...{error: action.error.response,success: null}};
    default:
      return state;
  }
}
