import axios from 'axios';
import store from '../../Store'
import {disableLoader, enableLoader} from "../loaders/loader";
import {networkError} from "../messages/success_message";
import {noInternet, serverError} from "../error";

function _success(success) {
  return {type: 'FETCH_NPAY_BANK_SUCCESS', success}
}

function _error(error) {
  return {type: 'FETCH_NPAY_BANK_ERROR', error}
}

export function getNpayBank() {
  return dispatch => {
    store.dispatch(enableLoader())
    let config = {
      url: localStorage.getItem('base_url') + `npay/ebanking`,
      method: "get",
      dataType: 'json',
      headers: {}
    };

    axios(config)

      .then(res => {
        store.dispatch(disableLoader())
        console.log(res)
        dispatch(_success(res));

      }).catch(error => {
      if (error.response) {
        if (error.response.status >= 500)
          store.dispatch(serverError())
        dispatch(_error(error));
      } else if (error.request) {
        store.dispatch(noInternet())
      }
      store.dispatch(disableLoader())
    });
  }
}

export default getNpayBank;
