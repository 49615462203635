import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Row} from "reactstrap";
import store from '../../Store'
import {initialPaymentService, initiatePayment, resetInitiatePayment} from "../../actions/topup/initiate_payment";
import MessageSnackBar from "../../shared/helpers/MessageSnackBar";
import ViewData from "../../shared/form-helpers/ViewData";
import {paymentConfirm, paymentConfirmService, resetPaymentConfirm} from "../../actions/topup/payment_confirm";
import PaymentHeader from "./PaymentHeader";
import npayValidate from "../../actions/npay/npay_validate";
import {disableLoader} from "../../actions/loaders/loader";
import ntInitiatePayment from "../../reducers/ntTopup/nt-initiate-payment";
import {loadStripe} from "@stripe/stripe-js";
import Stripe from "./Stripe"
import {Config} from "../../Config";
import {withFocusable, withNavigation} from "react-tv-navigation";
import {initiateNcellPayment} from "../../actions/topup/initiate_ncell_payment";
import QrCode from "./QrCode";
import {DetectMobile} from "../../shared/helpers/DetectMobile";
import {abortPayment} from "../../actions/payment/abort_payment";

const stripePromise = loadStripe(Config.StripePublicKey)

class IndividualPayment extends Component {
  data = this.props.location.state.data
  id = this.props.match.params.id
  state = {
    data: {},
    error: {},
    confirmData: {},
    confirmError: {},
    restConfirmSuccess: null,
    restSuccess: null,
    restConfirmError: null,
    restError: null,
    restKhaltiError: null,
    ncellSuccess: null,
    message: null,
    variant: 'success',
    confirmSuccess: null,
    seconds: 10,
    slug: '',
    npayParams: null
  }

  componentDidMount() {
    if (!this.props.location.state)
      this.props.history.push('/payment')
    this.setState({
        data: this.props.location.state.data,
        slug: this.props.match.params.id
      }, () => {
        if (this.id === "paypal") {
          setTimeout(() => {
            try {
              this.onPaypal()
            } catch (e) {
              console.log("Paypal is not initialized", e)
            }
          }, 50)
        }
      }
    )
  }

  onPaypal() {
    const _this = this
    window.paypal.Buttons({

      // Call your server to set up the transaction
      createOrder: function (data, actions) {

        return initialPaymentService(_this.state.data, "paypal")
          .then(res => {
            console.log(res)
            return res.data.id

          }).catch(error => {
            store.dispatch(disableLoader())
            _this.setState({
              message: error.response.data.message,
              variant: 'error'
            }, () => console.log(_this.state.message))
          })
      },

      // Call your server to finalize the transaction
      onApprove: function (data, actions) {
        return paymentConfirmService({order_id: data.orderID}, "paypal")
          .then(res => {
            if (res.data) {
              if (DetectMobile) {
                let _data = res.data ? res.data : {}
                let query = new URLSearchParams(_data).toString()
                try {
                  window.location.href = `/payment/confirm?status=success&${query}`
                } catch (e) {
                  _this.setState({
                    message: "Payment Failed",
                    variant: 'error'
                  })
                }
              } else
                _this.props.history.push('/payment/confirm', {response: res.data})
            }
          }).catch(error => {
            store.dispatch(disableLoader())
            try {
              _this.setState({
                message: error.response.data.message.toString(),
                variant: 'error'
              })
            } catch (e) {

            }
          })
      },

      onCancel: function () {
        _this.onRedirect()
      }

    }).render('#paypal-button-container');
  }


  onTopup(slug) {
    if (slug === "ncell") {
      let data = this.state.data;
      let isApp = data && data.ua ? data.ua : DetectMobile();
      store.dispatch(initiateNcellPayment(this.state.data, slug, isApp))
    }
    else
      store.dispatch(initiatePayment(this.state.data, slug))
  }

  onConfirmChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let confirmData = {...this.state.confirmData};
    let error = {...this.state.error};
    confirmData[name] = value;
    error[name] = "";
    this.setState({confirmData, error});
  }

  tick() {
    if (this.state.seconds === 1) {
      // window.location = 'https://worldlink.com.np/'
    } else
      this.setState(state => ({
        seconds: state.seconds - 1
      }));
  }

  onChange(e) {
    let {name} = e.target;
    let {value} = e.target;
    let data = {...this.state.data};
    let error = {...this.state.error};
    data[name] = value;
    error[name] = "";
    this.setState({data, error});
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      initiatePayment,
      paymentConfirm,
      npayValidate,
      ntInitiatePayment,
      initiateNcellPayment,
    } = nextProps

    let {
      restSuccess,
      restConfirmSuccess,
      restError,
      restConfirmError,
      restKhaltiError,
      npayParams,
      ncellSuccess
    } = prevState

    if (initiatePayment) {
      let {success, error} = initiatePayment
      if (success) {
        restSuccess = success.data
      } else if (error) {
        if (error.status === 422 || error.status === 400 || error.status === 404) {
          restError = error.data.message
        }
      } else {
        restSuccess = null
        restError = null
      }
    }

    if (ntInitiatePayment) {
      let {success, error} = initiatePayment
      if (success) {
        restSuccess = success.data
      } else if (error) {
        if (error.status === 422 || error.status === 400 || error.status === 404) {
          restError = error.data.message
        }
      } else {
        restSuccess = null
        restError = null
      }
    }

    if (paymentConfirm) {
      let {success, error} = paymentConfirm
      if (success) {
        restConfirmSuccess = success.data
      } else if (error) {
        if (error.status === 400 || error.status === 422) {
          restConfirmError = error.data.message
          if (error.data.message.detail) {
            restKhaltiError = error.data.message.detail
          }
        }
      } else {
        restConfirmSuccess = null
        restConfirmError = null
        restKhaltiError = null
      }
    }

    if (npayValidate) {
      let {success} = npayValidate
      if (success) {
        npayParams = success.data
      }
    }

    return {
      restSuccess,
      restConfirmSuccess,
      restError,
      restKhaltiError,
      restConfirmError,
      npayParams,
      ncellSuccess
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.restSuccess !== null) {
      this.setState({
        confirmData: {
          token: this.state.restSuccess.token,
          id: this.state.restSuccess.id
        }
      }, () => {
        store.dispatch(resetInitiatePayment())
      })
    }

    if (this.state.restKhaltiError !== null) {
      this.setState({
        message: this.state.restKhaltiError,
        variant: 'error'
      }, () => {
        store.dispatch(resetPaymentConfirm())
      })
    }

    if (this.state.restError !== null) {
      let message = null
      if (typeof this.state.restError === 'string' || this.state.restError instanceof String) {
        message = this.state.restError
      } else {
        let keys = Object.keys(this.state.restError)
        message = this.state.restError[keys[0]]
      }
      this.setState({
        message,
        variant: 'error'
      }, () => {
        store.dispatch(resetInitiatePayment())
      })
    }

    if (this.state.restConfirmError !== null) {
      if (typeof this.state.restConfirmError === "string") {
        this.setState({
          message: this.state.restConfirmError,
          variant: 'error'
        }, () => {
          store.dispatch(resetPaymentConfirm())
        })
      } else
        this.setState({
          error: this.state.restConfirmError
        }, () => {
          store.dispatch(resetPaymentConfirm())
        })
    }

    if (this.props.initiateNcellPayment && this.props.initiateNcellPayment !== prevProps.initiateNcellPayment) {
      let {success, error} = this.props.initiateNcellPayment
      if (success) {
        if (success.data) {
          this.redirectToPaymentPortal(success.data)
        } else {
          this.setState({
            message: 'Empty Response.',
            variant: 'error'
          })
        }
      } else if (error) {
        if (error.status === 422 || error.status === 400 || error.status === 404) {
          let message = null
          if (typeof error.data.message === 'string' || error.data.message instanceof String) {
            message = error.data.message
          } else {
            let keys = Object.keys(error.data.message)
            message = error.data.message[keys[0]]
          }
          this.setState({
            message,
            variant: 'error'
          })
        }
      }
    }

    if(this.props.paymentConfirm && this.props.paymentConfirm !== prevProps.paymentConfirm) {
      let {success} = this.props.paymentConfirm
      if(success) {
        let res = success.request.responseURL
        let resUrl = new URL(res)
        window.location.href = resUrl.pathname + resUrl.search
      }
    }

    if (this.state.npayParams !== prevState.npayParams) {
      this.redirectToPaymentPortal(this.state.npayParams)
    }
  }

  redirectToPaymentPortal(data) {
    let dataKeys = Object.keys(data)
    var path = data['action_url']
    var params = {}

    dataKeys.forEach((key) => {
      if (key !== "action_url")
        params[key] = data[key]
    })

    var form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", path);

    for (var key in params) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    store.dispatch(disableLoader())
    form.submit();
  }

  onDismiss() {
    this.setState({
      message: null
    });
  }

  onConfirm(slug) {
    store.dispatch(paymentConfirm(this.state.confirmData, slug))
  }

  onNpay(bank_code) {
    let data = {...this.props.location.state.data}
    let temp = {
      amount: data.amount,
      customer_code: data.customer_code,
      product: data.product,
      bank_code
    }
    store.dispatch(npayValidate(temp))
  }

  onStripePaymentSuccess(response) {
    if (DetectMobile) {
      let query = new URLSearchParams(response).toString()
      window.location.href = `/payment/confirm?status=success&${query}`
    } else
      this.props.history.push(`/payment/confirm?status=${response.status}&redirect_url=${response.redirect_url}`, {response: response})
    this.setState({
      message: "Payment Successful",
      variant: 'success',
      confirmSuccess: true
    }, () => {
      setInterval(() => this.tick(), 1000)
    })
  }

  // onRedirect() {
  //   this.props.history.push("/payment/cancel?status=fail")
  // }
  onRedirect() {
    store.dispatch(abortPayment({product: this.state.data.product})).then((response) => {
      if (response.status === 200 || response.status === 201) {
        this.props.history.push("/payment/cancel?status=fail")
      } else {
        this.props.history.push("/payment/cancel?status=fail")
      }
    })

  }

  render() {
    let {data, message, variant, confirmData, confirmSuccess, seconds, restConfirmSuccess, error, slug} = this.state

    if (slug === "khalti" || slug === "ntc" || slug === "stripe" || slug === "ncell" || slug === "paypal" || slug === "khalti-qr" || slug === "fonePay")
      return (
        <>
          {/*<div className="container">*/}
          <div className="container-fluid mx-0 px-0">
            <div className="row">
              <Card className="payment-card" style={{minHeight: "100vh"}}>
                <PaymentHeader
                  data={data}
                />
                <CardBody>
                  <div>
                    <div className="row justify-content-center ml-3 mr-3">
                      <div style={{width: "200px", marginBottom: "1rem"}}>
                        <div className="row rounded py-3 px-2 shadow-sm bg-white h-100 b-2">
                          <div className="col-12">
                            <img src={data.logoUrl} className={`gateway-logo right-side-payment-logo ${slug}`}
                                 alt={slug}/>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  {this.id === "stripe" ?
                    <>
                      <Stripe stripePromise={stripePromise} data={data}
                              onSuccess={this.onStripePaymentSuccess.bind(this)}/>
                    </> :
                    this.id === "paypal" ?
                      <>
                        <div className="d-flex justify-content-center">
                          <div style={{width: "400px", maxWidth: "800px"}}>
                            <div id="paypal-button-container"></div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <div className="text-center">
                            <FocusableButton click={() => this.onRedirect()}
                                             focusPath={`individual-payment-cancel`}
                                             className="px-3"
                                             size={"lg"}
                                             color={"default"}
                                             text={"Cancel"}
                                             onEnterPress={() => this.onRedirect()}/>
                          </div>
                        </div>
                      </>
                      :
                      (this.id === "fonePay" || this.id === "khalti-qr") ?
                        <>
                          <QrCode
                            id={this.id}
                            data={this.state.data}
                          />
                        </>
                        :
                        <>
                          {confirmSuccess ?
                            <>
                              {slug === "ntc" ?
                                <>
                                  <Row className="justify-content-center">
                                    <Col md="3">
                      <span className="span-link download-link">
                        Download Transaction details.
                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="12" lg="4" md="6">
                                      <ViewData
                                        label="NTC Idx"
                                        data={restConfirmSuccess.response.payment_id}
                                      />
                                    </Col>
                                    <Col sm="12" lg="4" md="6">
                                      <ViewData
                                        label="Transaction Id"
                                        data={restConfirmSuccess.response.transaction_id}
                                      />
                                    </Col>
                                    <Col sm="12" lg="4" md="6">
                                      <ViewData
                                        label="Amount"
                                        data={restConfirmSuccess.response.amount}
                                      />
                                    </Col>
                                    <Col sm="12" lg="4" md="6">
                                      <ViewData
                                        label="Customer Code"
                                        data={restConfirmSuccess.response.customer_code}
                                      />
                                    </Col>
                                    <Col sm="12" lg="4" md="6">
                                      <ViewData
                                        label="Payment method"
                                        data={restConfirmSuccess.response.payment_method}
                                      />
                                    </Col>
                                  </Row>
                                </> :
                                <>
                                  <Row className="justify-content-center">
                                    <Col md="3">
                      <span className="span-link download-link">
                        Download Transaction details.
                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="12" lg="4" md="6">
                                      <ViewData
                                        label="Khalti Idx"
                                        data={restConfirmSuccess.response.khalti_idx}
                                      />
                                    </Col>
                                    <Col sm="12" lg="4" md="6">
                                      <ViewData
                                        label="Transaction Id"
                                        data={restConfirmSuccess.response.transaction_id}
                                      />
                                    </Col>
                                    <Col sm="12" lg="4" md="6">
                                      <ViewData
                                        label="Amount"
                                        data={restConfirmSuccess.response.amount}
                                      />
                                    </Col>
                                    <Col sm="12" lg="4" md="6">
                                      <ViewData
                                        label="Customer Code"
                                        data="CUS-000001"
                                      />
                                    </Col>
                                    <Col sm="12" lg="4" md="6">
                                      <ViewData
                                        label="Item"
                                        data="Test Package"
                                      />
                                    </Col>
                                  </Row>
                                </>
                              }
                            </>
                            : confirmData.token ?
                              <>
                                <div>
                                  <p className="form-span-label text-center confirmation-text">
                                    Confirmation Code has been sent to your mobile. It may take about a minute
                                  </p>
                                </div>

                                <Row className="justify-content-center">
                                  <Col sm="12" md="4">
                                    <p className="form-span-label text-center">Confirmation Code</p>
                                    <FormGroup>
                                      <FocusableInput className="form-control"
                                                      name="confirmation_code"
                                                      autoComplete="new-password"
                                                      focusPath={`individual-payment-input-confirmation-code`}
                                                      type="text"
                                                      invalid={!!error.confirmation_code}
                                                      change={(e) => this.onConfirmChange(e)}/>
                                      <FormFeedback>{error.confirmation_code}</FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm="12" md="4">
                                    <p className="form-span-label text-center">Transaction Pin</p>
                                    <FormGroup>
                                      <FocusableInput className="form-control"
                                                      name="transaction_pin"
                                                      focusPath={`individual-payment-input-transaction-pin`}
                                                      autoComplete="new-password"
                                                      type="password"
                                                      invalid={!!error.transaction_pin}
                                                      change={(e) => this.onConfirmChange(e)}/>
                                      <FormFeedback>{error.transaction_pin}</FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                              : confirmData.id ?
                                <>
                                  <div>
                                    <p className="form-span-label text-center confirmation-text">
                                      Confirmation Code has been sent to your mobile. It may take about a minute
                                    </p>
                                  </div>

                                  <Row className="justify-content-center">
                                    <Col sm="12" md="4">
                                      <p className="form-span-label text-center">Confirmation Code</p>
                                      <FormGroup>
                                        <FocusableInput
                                          className="form-control"
                                          focusPath={`individual-payment-input-otp`}
                                          invalid={!!error.otp}
                                          name="otp"
                                          autoComplete="new-password"
                                          type="password"
                                          change={(e) => this.onConfirmChange(e)}/>
                                        <FormFeedback>{error.otp}</FormFeedback>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </> :
                                <>
                                  <Row className="justify-content-center">
                                    <Col sm="12" md="6">
                                      <p className="form-span-label text-left text-md-center">Mobile Number</p>
                                      <FormGroup>
                                        <FocusableInput className="form-control"
                                                        name="mobile"
                                                        type="text"
                                                        focusPath={`individual-payment-input-mobile`}
                                                        change={(e) => this.onChange(e)}/>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </>
                          }
                          {confirmSuccess ? <>
                              <Row className="justify-content-center">
                                <Col sm="12" md="4">
                                  <div className="text-center">
                                    <p className="form-span-label text-center confirmation-text">
                                      You will be redirected back in {seconds} seconds
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </> :
                            <Row className="justify-content-center">
                              <Col sm="12" md="6">
                                <div className="text-center">
                                  <FocusableButton
                                    click={(confirmData.token || confirmData.id) ? this.onConfirm.bind(this, slug) : this.onTopup.bind(this, slug)}
                                    focusPath={`individual-payment-success`}
                                    className="px-3 mr-3"
                                    size={"lg"}
                                    color={"success"}
                                    text={confirmData.token ? "Confirm" : "Pay"}
                                    onEnterPress={(confirmData.token || confirmData.id) ? this.onConfirm.bind(this, slug) : this.onTopup.bind(this, slug)}/>
                                  <FocusableButton click={() => this.onRedirect()}
                                                   focusPath={`individual-payment-cancel`}
                                                   className="px-3"
                                                   size={"lg"}
                                                   color={"default"}
                                                   text={"Cancel"}
                                                   onEnterPress={() => this.onRedirect()}/>
                                </div>
                              </Col>
                            </Row>
                          }
                        </>
                  }

                </CardBody>
              </Card>
            </div>
          </div>
          {/*</div>*/}
          <MessageSnackBar
            open={!!message}
            onClose={this.onDismiss.bind(this)}
            variant={variant}
            message={message}
          />
        </>
      );

    if (slug === "npay") {
      let banklist = this.props.location.state.bankList.map((single) => {
        return <img src={single.bank_image}
                    className="gateway-logo gateway-logo-margin"
                    border="5" alt={single.bank_name} onClick={() => this.onNpay(single.bank_code)}
        />
      })
      return (
        <div className="row justify-content-center payment-card-parent">
          <Card className="payment-card">
            <CardBody>
              <div>
                <PaymentHeader
                  data={data}
                />
                <Row className="justify-content-center">
                  <Col sm="12">
                    {banklist}
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </div>
      );
    }

    return null
  }
}

const _Button = ({className, text, color, size = "lg", focused, setFocus, click}) => {
  function onClick() {
    setFocus()
    click()
  }

  return (
    <Button size={size} className={`${className} ${(focused) ? 'focus' : ''}`} color={color}
            onClick={() => onClick()}>
      {text}
    </Button>
  )
}
const FocusableButton = withFocusable(_Button)

const _FocusableInput = ({className, setFocus, focused, name, autoComplete, focusPath, type, invalid, change}) => {
  return (
    <Input className={`${className} ${(focused) ? 'focus' : ''}`}
           name={name}
           autoComplete={autoComplete}
           focusPath={focusPath}
           type={type}
           invalid={invalid}
           onClick={() => setFocus()}
           onChange={(e) => change(e)}/>
  )
}
const FocusableInput = withFocusable(_FocusableInput)

function mapStateToProps(state) {
  let {
    initiatePayment,
    paymentConfirm,
    ntInitiatePayment,
    initiateNcellPayment,
    npayValidate,
    loader
  } = state
  return {
    initiatePayment,
    paymentConfirm,
    ntInitiatePayment,
    initiateNcellPayment,
    npayValidate,
    loader
  }
}

export default withNavigation(connect(mapStateToProps)(IndividualPayment));
