import axios from 'axios';
import { disableLoader, enableLoader} from "../loaders/loader";
import store from '../../Store'

function _success(success) {
  return {type: 'INITIATE_QR_SUCCESS', success}
}

function _error(error) {
  return {type: 'INITIATE_QR_ERROR', error}
}


export function initiateQr(url, data) {
  return dispatch => {

    store.dispatch(enableLoader())

    initialQrService(url, data)
      .then(res => {
        store.dispatch(disableLoader())
        // dispatch(_processing(false));
        dispatch(_success(res));

      }).catch(error => {
        store.dispatch(disableLoader())
        dispatch(_error(error));
        // dispatch(_processing(false));
      })

    // return {type: 'INITIATE_QR_SUCCESS', payload: request}
  }
}

export function initialQrService(url,data) {
  let config = {
    url: localStorage.getItem('base_url') + url ,
    method: "post",
    dataType: 'json',
    accept: "application/json",
    contentType: "application/json",
    data: data,
    headers: { 'content-type': 'application/json' }
  };
  return axios(config)
}

export default initiateQr;
