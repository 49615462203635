export const ArrayValue = (datas, key, id, value) => {
  if (datas) {
    for (let i = 0; i < datas.length; i++) {
      if (datas[i][id] == key) {
        return datas[i][value]
      }
    }
  }
}

export const isEmptyObject = (params) => {
  if (params) {
    let keys = Object.keys(params)
    return keys.length <= 0;
  }
}
