export default function loader(state = { isLoader: false, isInnerLoader: false }, action) {
  switch (action.type) {
    case "ENABLE_LOADER":
      return { ...state, ...{ isLoader: true } };
    case "DISABLE_LOADER":
      return { ...state, ...{ isLoader: false } };
    case "ENABLE_INNER_LOADER":
      return { ...state, ...{ isInnerLoader: true } };
    case "DISABLE_INNER_LOADER":
      return { ...state, ...{ isInnerLoader: false } };
    default:
      return state;
  }
}

