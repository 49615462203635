import axios from 'axios';
import store from '../../Store'
import {disableLoader, enableLoader} from "../loaders/loader";
import {noInternet, serverError} from "../error";

function _error(error) {
  return {type: 'PAYMENT_CONFIRM_ERROR', error}
}

function _success(success) {
  return {type: 'PAYMENT_CONFIRM_SUCCESS', success}
}

export function resetPaymentConfirm() {
  return dispatch => {
    dispatch({
      type: "RESET_PAYMENT_CONFIRM"
    });
  };
}


export function paymentConfirm(data, slug) {
  return dispatch => {
    store.dispatch(enableLoader())

    paymentConfirmService(data, slug)
      .then(res => {
        store.dispatch(disableLoader())
        console.log("response", res)
        store.dispatch(_success(res))
        // window.location = res.request.responseURL

      }).catch(error => {
      store.dispatch(disableLoader())
      if (error.response) {
        if (error.response.status >= 500)
          store.dispatch(serverError())
      } else if (error.request) {
        store.dispatch(noInternet())
      } else {
        store.dispatch(serverError())
      }
      dispatch(_error(error));
    })
  }
}

export function paymentConfirmService(data, slug) {
  let config = {
    url: localStorage.getItem('base_url') + slug + `/confirm`,
    method: "post",
    data: data,
    headers: {}
  };

  if (slug === "ntc") {
    config["url"] = localStorage.getItem('base_url') + `ntc/validate-auth-password`
    // config["url"] =  "http://localhost:8005/" +  `nt/validate-auth-password`
  }

  return axios(config)
}

export default paymentConfirm;
