import axios from 'axios';
import store from '../../Store'
import {disableLoader, enableLoader} from "../loaders/loader";
import {_success} from '../connect_reducer'
import {noInternet, serverError} from "../error";

export class GetGroup {
  constructor(limit = null, page = 1, filters = [], url, reducers = {},dateFilter={}) {
    this.limit = limit
    this.page = page
    this.filters = filters
    this.url = url
    this.reducers = reducers
    this.dateFilter = dateFilter
  }

  getGroup() {
    store.dispatch(enableLoader())
    let config = {
      headers: {
      },
      params: {
        limit: this.limit || 9,
        page: this.page,
        start_date: this.dateFilter.start_date,
        end_date: this.dateFilter.end_date
      }
    };

    for (let i = 0; i < this.filters.length; i++) {
      config.params["filter[" + this.filters[i].key + "]"] = this.filters[i].value;
    }

    axios.get(localStorage.getItem('base_url') + this.url, config)
      .then(res => {
        store.dispatch(_success(res, this.reducers.success))
        store.dispatch(disableLoader())

      })
      .catch(error => {
        store.dispatch(disableLoader())
        if (error.response){
          if (error.response.status >= 500)
            store.dispatch(serverError())
        }
        else if (error.request){
          store.dispatch(noInternet())
        }
        else {
          store.dispatch(serverError())
        }
      });
  }
}
