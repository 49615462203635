export function enableLoader() {
  return dispatch => {
    dispatch({
      type: "ENABLE_LOADER"
    });
  };
}

export function disableLoader() {
  return dispatch => {
    dispatch({
      type: "DISABLE_LOADER"
    });
  };
}

export function enableInnerLoader() {
  return dispatch => {
    dispatch({
      type: "ENABLE_INNER_LOADER"
    });
  };
}

export function disableInnerLoader() {
  return dispatch => {
    dispatch({
      type: "DISABLE_INNER_LOADER"
    });
  };
}
