import React, {Component} from 'react'
import logo from "../../assets/img/brand/89_25.png";
import {Card, CardBody, Col, Row} from "reactstrap";
import queryString from "query-string";
import {stringUpperCase} from "../../shared/helpers/GeneralHelpers";
import {isEmptyObject} from "../../shared/helpers/ArrayKey";
import {downloadFile} from "../../shared/helpers/DownloadFile";
import {Config} from "../../Config";
import {DetectMobile} from "../../shared/helpers/DetectMobile";

class ConfirmPayment extends Component {
  state = {
    response: {},
    seconds: 0,
    redirected: false,
    parsedUrl: "",
  }

  componentDidMount() {
    if (this.props.location.state)
      if (this.props.location.state.response)
        this.setState({
          response: this.props.location.state.response,
          seconds: this.props.location.state.response.time_out ? this.props.location.state.response.time_out : 10
        }, () => {
          setInterval(() => this.tick(), 1000)
        })
      else
        this.props.history.push('/payment')
    else {
      const response = queryString.parse(this.props.location.search);
      if (isEmptyObject(response))
        this.props.history.push('/payment')
      this.setState({
        response,
        seconds: response.time_out ? response.time_out : 10
      }, () => {
        if (!DetectMobile())
          setInterval(() => this.tick(), 1000)
      })
    }
  }

  tick() {
    if (this.state.seconds === 1 && this.state.redirected === false) {
      // let url = this.props.location.search.split("redirect_url=")[1]
      // let parameters = url.split("?");
      // let queryParameters = parameters[1].split(",")
      // let parsedUrl = parameters[0] + "?"
      // for (let i = 0; i < queryParameters.length; i++) {
      //   parsedUrl += queryParameters[i]
      //   parsedUrl += "&"
      // }
      // parsedUrl = parsedUrl.substr(0, parsedUrl.length - 1)
      if(localStorage.getItem('callback_url') && !DetectMobile()) {
        window.location = localStorage.getItem('callback_url')
      }
      this.setState({
        // parsedUrl,
        redirected: true
      })
    } else if (this.state.redirected === false)
      this.setState(state => ({
        seconds: state.seconds - 1
      }));
  }

  onDownload() {
    let {response} = this.state
    let url = Config.BillingUrl + `transactions/${response.transaction_id}?q=print`
    downloadFile(url, response.transaction_id)
  }

  onRedirect() {
    window.location = localStorage.getItem('callback_url')
  }

  render() {

    let {response, seconds} = this.state

    let responseKey = Object.keys(response)

    let viewResponse = responseKey.map((key) => {
      if (key === "redirect_url" || key === "time_out" || key === "status" || key === "message" || key === "action_base_url" || key === "callback_url" || response[key] === "null") {
        return null
      } else {
        return (
          <>
            <div className="text-muted">
              {stringUpperCase(key)}
            </div>
            <div className="text-bold">
              <span className="text-bold">{response[key]}</span>
            </div>
          </>
        )
      }
    })

    return (
      <div className="row justify-content-center payment-card-parent">
        <Card className="payment-card">
          <CardBody>
            <div className="payment-title-div">
              <span className="payment-title-text">ePayment</span>
            </div>
            <div className="right-side-div">
              {response.status !== "fail" &&
              <Row className="justify-content-center">
                <Col sm="" md="6">
                  <Card style={{borderWidth: 0}}>
                    <CardBody>
                      <div className="payment-info">
                        <div className="text-center payment-text-box">
                          <div style={{fontSize: '14px'}}>Your transaction was successful with following details.</div>
                          {viewResponse}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              }

              {response.status === "fail" ?
                <div>
                  <p className="payment-error confirmation-text text-center">
                    {`${response.message} `}{!DetectMobile() && <span>To go back <span className="span-link"
                                                                                       onClick={() => this.onRedirect()}>here</span></span>}
                  </p>
                </div>
                :
                <>
                  {/*<div>*/}
                  {/*  <p className="form-span-label text-center confirmation-text">*/}
                  {/*    Save the transaction details for future reference. <span className="span-link"*/}
                  {/*                                                             onClick={this.onDownload.bind(this)}>Download</span>*/}
                  {/*  </p>*/}
                  {/*</div>*/}
                  <hr/>
                  {!DetectMobile() && <div>
                    <p className="form-span-label text-center confirmation-text">
                      {this.state.seconds === 1 ?
                        <span>If you are not redirected back. Please Click <span className="span-link"
                                                                                 onClick={() => this.onRedirect()}>here</span></span> :
                        <span>You will be redirected back in {seconds} second automatically. Or click <span
                          className="span-link"
                          onClick={() => this.onRedirect()}>here</span> to go back.</span>}
                        </p>
                  </div>}
                </>}
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default ConfirmPayment
