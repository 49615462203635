export function addSuccessMessage(message) {
  return dispatch => {
    dispatch({
      type: "ADD_SUCCESS_MESSAGE",
      message
    });
  };
}

export function deleteSuccessMessage() {
  return dispatch => {
    dispatch({
      type: "DELETE_SUCCESS_MESSAGE"
    });
  };
}

export function networkError() {
  return dispatch => {
    dispatch({
      type: "NETWORK_ERROR"
    });
  };
}

export function resetNetworkError() {
  return dispatch => {
    dispatch({
      type: "RESET_NETWORK_ERROR"
    });
  };
}
