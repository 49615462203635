export function _success(success, reducer) {
  return dispatch => {
    dispatch({
      type: reducer, success
    });
  };
}

export function _error(error, reducer) {
  return dispatch => {
    dispatch({
      type: reducer, error
    });
  };
}

export function _validation_error(validation, reducer) {
  return dispatch => {
    dispatch({
      type: reducer, validation
    });
  }
}

export function _bad_gateway_error(gateway, reducer) {
  return dispatch => {
    dispatch({
      type: reducer, gateway
    });
  }
}
