
import React from 'react';
import ReactDOM from 'react-dom';
import Provider from "react-redux/es/components/Provider";
import store from "./Store";
import Routes from "./routes/Routes";
import './assets/required.css';
import './assets/datatables.css';
import './assets/css/custom.css'
import 'simple-line-icons'
import {BrowserRouter} from "react-router-dom";

const provider = (
  <Provider store={store}>
    <BrowserRouter>
      <Routes/>
    </BrowserRouter>
  </Provider>
);

export default provider;

ReactDOM.render(provider, document.getElementById('root'));
