import {GetGroup} from "../actions/crud/get_group";
import {Get} from "../actions/crud/get";

const prefix = "payment-gateways"

export const getPaymentGateway = (id) => {
  let reducers = {
    success: 'FETCH_PAYMENT_GATEWAY_SUCCESS',
    error: 'FETCH_PAYMENT_GATEWAY_ERROR'
  }
  let url = prefix + `/${id}/config`
  let get = new Get(url, reducers)
  get.get()
}

export const getPaymentGateways = () => {
  let reducers = {
    success: 'FETCH_PAYMENT_GATEWAYS_SUCCESS',
    error: 'FETCH_PAYMENT_GATEWAYS_ERROR'
  }
  let temp = [{key: 'status',value: 1}]
  let group = new GetGroup(50, null, temp, prefix, reducers)
  group.getGroup()
}

